import { Route, Routes } from "react-router-dom";
import HomePage from "./pages/HomePage/home";
import Documentation from "components/privatPages/documentation";
import Contacts from "pages/Contacts/contacts";
import policyjson from "./json/privacypoolicy.json";
import agreementjson from "./json/agreement.json";
import "./App.css";
import ModalLodaer from "components/ModalLoader/modalLodaer";
import { useContext, useState } from "react";
import { AppContext } from "Context";
import Donate from "pages/Donate/Donate";
import Header from "components/Header/header";
import Footer from "components/Footer/Footer";
import Referral from "./pages/Referral/Referral";
import ScrollToTop from "hooks/useScrollTop";

const App = () => {
  const { isVisible, text }: any = useContext(AppContext as any);
  const [mouseCords, setMouseCords] = useState({ mouseX: 0, mouseY: 0 });

  const handleMouseMove = (event: any) => {
    setMouseCords({ mouseX: event.clientX, mouseY: event.clientY });
  };

  return (
    <div onMouseMove={(e) => handleMouseMove(e)} className="app__main_box">
      <Header />
      <ScrollToTop />
      <div className="app-wrapper">
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route
            path="/libertypolicy"
            element={
              <Documentation
                props={policyjson[1]}
                title={"Політика конфіденціальності"}
              />
            }
          />
          <Route
            path="/libertyagreement"
            element={
              <Documentation props={agreementjson} title={"Публічна оферта"} />
            }
          />
          <Route path="/libertycontacts" element={<Contacts />} />
          <Route path="/donate" element={<Donate mouseCords={mouseCords} />} />
          <Route element={<HomePage />} />
          <Route
            path="/referral"
            element={<Referral mouseCords={mouseCords} />}
          />
        </Routes>
      </div>
      {isVisible && <ModalLodaer text={text} />}
      <Footer />
    </div>
  );
};

export default App;
