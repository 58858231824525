import axios from "axios";

interface CustomError extends Error {
  code: string;
}

export const handlePayment = async (
  login: string,
  LC: number,
  showModalWindowhandler: (message: string) => void
): Promise<void> => {
  const data = {
    Login: login.toLocaleLowerCase(),
    LC: LC,
  };

  try {
    const response = await axios.post(
      "https://payments.liberty-rp.com.ua/Payment/create-payment",
      data
    );
    const redirectUrl = response.data.payLink;
    if (redirectUrl) {
      window.open(redirectUrl, "_self");
    }
  } catch (error) {
    const customError = error as CustomError;
    if ((customError.code as string) === "ERR_BAD_REQUEST") {
      showModalWindowhandler(`Такого логіну не існує`);
    } else {
      showModalWindowhandler("Щось пішло не так, зверніться до адміністрації");
    }
  }
};
