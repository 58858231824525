import React, { FC, useContext } from "react";
import UseLinkNavigate from "hooks/useLinkNavigate";
import { AppContext } from "Context";
import copy from "clipboard-copy";
import { _ipServerAdress } from "json/ip";

type Props = {
  id: number;
  title: string;
  index: number;
  description: string;
  btn: string | null;
  images: string[];
};

const Howtoplaycard: FC<Props> = ({
  title,
  index,
  description,
  btn,
  images,
  id,
}) => {
  const { showModalWindowhandler, isVisible }: any = useContext(
    AppContext as any
  );

  const copyAdressHandler = (link: string): void => {
    if (isVisible) return;
    copy(link);
    showModalWindowhandler("Cкопійовано в буфер обміну");
  };

  const navigateLinkHandler = (id: number) => {
    switch (id) {
      case 3:
        copyAdressHandler(_ipServerAdress);

        break;
      case 2:
        UseLinkNavigate(5);
        break;
      case 1:
        UseLinkNavigate(4);
        break;
    }
  };

  return (
    <div className="howtoplay__card_wrapper">
      <h1 className="howtoplay__card_index">0{index}</h1>
      <div className="howtoplay__card_blockwrapper">
        <div className="howtoplay__card_clippath--block" />
        <div className="howtoplay__card_content">
          <img
            src={images[id - 1]}
            alt="persongta"
            className="howtoplay__persongta"
          />
          <div className="howtoplay__card_text">
            <span className="howtoplay__card_title">{title}</span>
            <h5 className="howtoplay__card_description">{description}</h5>
            {btn && (
              <button
                className="howtoplay__card_button"
                onClick={() => navigateLinkHandler(id as number)}
              >
                {btn}
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Howtoplaycard;
