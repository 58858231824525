import { FC } from "react";
import "./ListNumberInfo.sass";

type oneObject = {
  id: number;
  text: string;
};

interface IPropsNumberInfo {
  list: oneObject[];
}

const ListNumberInfo: FC<IPropsNumberInfo> = ({ list }) => {
  return (
    <ul className="ul-list-number-info">
      {list.map(({ id, text }, index) => (
        <li key={id} className="li-list-number-info">
          <span className="number">{index + 1}</span>{" "}
          <p className="text" dangerouslySetInnerHTML={{ __html: text }}></p>
        </li>
      ))}
    </ul>
  );
};

export default ListNumberInfo;
