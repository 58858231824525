import { useContext, useState } from "react";
import "./DonateForm.sass";
import copy from "clipboard-copy";

import sprite from "assets/Images/sprites/sprites.svg";
import { AppContext } from "Context";
import { bonuses } from "json/donate/donateInfoBonus";
import { handlePayment } from "api/donate";
const DonateForm = () => {
  const { showModalWindowhandler, isVisible }: any = useContext(
    AppContext as any
  );
  const defaultValueForm = {
    account: "",
    count: "",
  };

  const [formData, setFormData] = useState(defaultValueForm);

  const handleChangeInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value.trim(),
    }));
  };

  const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();
    console.log(formData);
    if (formData.account !== "" && formData.count !== "") {
      handlePayment(
        formData.account,
        Number(formData.count),
        showModalWindowhandler
      );
      setFormData(defaultValueForm);
    } else {
      showModalWindowhandler("Заповніть всі поля");
    }
  };
  // const handleSubmit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
  //   e.preventDefault();
  //   let link = "https://t.me/igor_yakymiv";
  //   // window.open("https://t.me/igor_yakymiv", "_blank");
  //   copy(link);
  //   showModalWindowhandler("Cкопійовано в буфер обміну");
  // };

  const handleCountCoin = (money: number) => {
    const current = bonuses.filter((item) => item.money <= money);
    if (current[current.length - 1]) {
      const summ = money + money * (current[current.length - 1].bonus / 100);
      return Math.round(summ);
    } else {
      return money;
    }
  };

  return (
    <form className="form-box ">
      <label className="donate-form-label" htmlFor="account">
        Логін ( При вході )
        <input
          value={formData.account}
          type="text"
          onChange={(e) => handleChangeInput(e)}
          placeholder="Вкажіть аккаунт"
          className="donate-form-input"
          name="account"
          id="account"
        ></input>
      </label>
      <label className="donate-form-label" htmlFor="count">
        Cума поповнення ( в Гривнях )
        <input
          value={formData.count}
          type="number"
          onChange={(e) => handleChangeInput(e)}
          placeholder="Введіть суму"
          name="count"
          className="donate-form-input"
          id="count"
        />
      </label>
      <p className="donate-form-text-result">
        При поповненні цієї суми ви отримуєте
        <span className="donate-form-text-result-span">
          {handleCountCoin(Number(formData.count))}
          <svg className="donate-form-coin">
            <use href={`${sprite}#coin`} />
          </svg>
        </span>
      </p>
      {/* <p className="text-admin">
        Для оформлення донату зверніться до адміністратора
      </p> */}

      <button
        onClick={(e) => handleSubmit(e)}
        className="donate-form-button-submit"
      >
        {/* Скопіювати посилання Адміністрації */}
        Продовжити
      </button>
    </form>
  );
};

export default DonateForm;
