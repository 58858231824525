import React, { FC, useContext, useEffect, useRef } from "react";
import HeaderButtons from "../../json/header.json";
import UseLinkNavigate from "hooks/useLinkNavigate";
import { useNavigate } from "react-router-dom";
import { AppContext } from "Context";

type Props = {};

const Rightmenu: FC<Props> = () => {
  const navigate = useNavigate();
  const menuRef = useRef<HTMLDivElement>(null);

  const { toogleMenuState, changeToogleMenuHandler }: any = useContext(
    AppContext as any
  );

  const redirectHandler = (id: number) => {
    if (id === 0 || id === 3) {
      navigate("/");
    } else if (id === 6) {
      navigate("/donate");
    }
    setTimeout(() => {
      UseLinkNavigate(id);
    }, 100);
    if (toogleMenuState) changeToogleMenuHandler();
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (menuRef.current && !menuRef.current.contains(event.target as Node)) {
      changeToogleMenuHandler();
    }
  };

  // window.addEventListener("mousedown", handleClickOutside);

  return (
    <div
      className="header__background_clicker"
      onClick={handleClickOutside as any}
    >
      <div
        ref={menuRef}
        className={`header__burger_menu ${
          toogleMenuState ? "slide-in" : "slide-out"
        }`}
      >
        <ul className="header__burger_buttons">
          {HeaderButtons.map((el) => (
            <li key={el.id}>
              <button
                className="header__burger_button"
                onClick={() => redirectHandler(el.id)}
              >
                <span>{el.title}</span>
                <div className="header__burger_underline" />
              </button>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default Rightmenu;
